<script>
import { Components } from "manageplaces-ui-kit";
import CompanyUsersQuery from "@/graphql/queries/CompanyUsers.gql";
import { mapState } from 'vuex';

export default {
  extends: Components.BaseTable,
  apollo: {
    users: {
      query: CompanyUsersQuery,
      variables() {
        return {
          id: this.$route.params.id,
          isDeleted: this.isDeleted
         };
      },
      update(data) {
        return data.company.users;
      },
      result() {
        this.setRowData(this.users);
        this.stopLoading();
      },
    },
  },
  data() {
    return {
      columns: [
        { headerName: "Name", field: "name", sortable: true, flex: 1 },
        { headerName: "Email", field: "email", sortable: true },
        { headerName: "Created", field: "createdAt", sortable: true, maxWidth: 150, cellRenderer: "date",
          cellRendererParams: {
          time: true
        }
        },
        { headerName: "Last activity", field: "lastActivity", sortable: true, maxWidth: 150, cellRenderer: "date",
          cellRendererParams: {
          time: true
        }
        },
        { headerName: "Role", field: "roleName", sortable: true },
        { headerName: "License", field: "userLicense",
          valueGetter(users) { return users.data.userLicense? "Standard" : "None";
          },
          sortable: true },
        { headerName: "Is deleted?", field: "isDeleted", sortable: true, maxWidth: 120 },
        {
          headerName: "",
          cellRenderer: "actionsMenu",
          width: 100,
          resizable: false,
        },
      ],
      users: []
    };
  },
  mounted() {
    this.startLoading();
  },

  computed: mapState(["isDeleted"]),

  methods: {
    getContextMenuItems(user) {
      let actionMenuItems = []
      if(!user.isDeleted) {
        if(!user.invitationAcceptedAt){
          actionMenuItems.push(
            {
              label: "Resend invitation",
              action: "resendInvitation",
              icon: "document"
            });
        }
          actionMenuItems.push(
            {
              label: "Update",
              action: "updateUser",
              icon: "edit",
            },
            {
              label: "Reset password",
              action: "resetPassword",
              icon: "padlock",
            },
            {
              label: "Delete",
              action: "deleteUser",
              icon: "delete",
              iconColour: "var(--withered-cherry)",
            });
        }
      return actionMenuItems
    },
    contextMenuItemClicked(item, row) {
      const user = row.data;
      this.$emit("ctxClick", item.action, user);
    },
    getActionBarContent(h) {
      const isDeleted = h(Components.AppCheckbox, {
        staticClass: "flex-1 mr-4",
        props: { value: this.isDeleted, label: 'Show deleted' },
        on: { input: () =>
        {
          this.$store.commit('setCompanyUsersIsDeleted',!this.isDeleted)
        }}
      });
      return [
        h("div", {
          staticClass: "flex flex-row",
        }, [isDeleted]),
      ];
    },
  },
};
</script>
