<template lang="pug">
  users-table(height="300px", :actionBar="false", @ctxClick="contextMenuClicked")
    template(slot="buttons")
      app-button(@click="$router.push(`/companies/${$route.params.id}/users/new`)", primary) Add user

</template>
<script>
import UsersTable from "@/components/tables/CompanyUsersTable.vue";
import DeleteUserMutation from "@/graphql/mutations/companies/company_users/DeleteUserMutation.gql";
import ResetPasswordMutation from "@/graphql/mutations/companies/company_users/ResetPasswordMutation.gql";
import ResendInvitationMutation from "@/graphql/mutations/companies/company_users/ResendInvitationMutation.gql";
import CompanyUsersQuery from "@/graphql/queries/CompanyUsers.gql";

export default {
  components: {
    UsersTable,
  },
  methods: {
    contextMenuClicked(action, user) {
      switch (action) {
        case "updateUser":
          {
            this.$router.push(`users/${user.id}/update`);
          }
          break;

        case "deleteUser":
          this.deleteUser(user.id);
          break;

        case "resetPassword":
          this.resetPassword(user);
          break;

        case "resendInvitation":
          this.resendInvitation(user);
          break;
      }
    },
    deleteUser(userId) {
      const vm = this;
      this.$dialog
        .confirm({
          title: "Confirm user deletion",
          message: "Are you sure you want to delete this user?"
        })
        .onOk(({ api }) => {
          api.hide();
          this.$apollo
            .mutate({
              mutation: DeleteUserMutation,
              variables: {
                input: {
                  userIds: [userId]
                }
              },
              update(store, { data: { deleteUsers } }) {
                const variables = {
                  id: vm.$route.params.id,
                  isDeleted: vm.$store.state.isDeleted
                };
                console.log(variables)
                if (deleteUsers.users[0]) {
                  const data = store.readQuery({
                    query: CompanyUsersQuery,
                    variables
                  });
                  console.log(data.company.users)
                  const idx = data.company.users.findIndex(
                    (u) => u.id === deleteUsers.users[0].id
                  );
                  data.company.users.splice(idx, 1);
                  store.writeQuery({
                    query: CompanyUsersQuery,
                    variables,
                    data
                  });
                }
              }
            })
            .then(() => {
              this.$store.commit('decreaseCompanyUserCount'),
              this.$flash.success("User successfully deleted");
            });
        });
    },
    resetPassword(user) {
      const name = user.name;
      const email = user.email;
      this.$dialog
        .confirm({
          title: `Are you sure you want to reset ${name}'s password?`,
          message: `A reset password email will be sent to ${email} on confirmation`
        })
        .onOk(({ api }) => {
          api.hide();
          this.$apollo
            .mutate({
              mutation: ResetPasswordMutation,
              variables: {
                input: {
                  id: user.id
                }
              },
            })
            .then(() => {
              this.$flash.success("Reset password email sent");
            });
        });
    },
    resendInvitation(user) {
      const name = user.name;
      const email = user.email;
      this.$dialog
        .confirm({
          title: `Are you sure you want to resend ${name}'s invitation?`,
          message: `An invite email will be sent to ${email} on confirmation`
        })
        .onOk(({ api }) => {
          api.hide();
          this.$apollo
            .mutate({
              mutation: ResendInvitationMutation,
              variables: {
                input: {
                  id: user.id
                }
              },
            })
            .then(() => {
              this.$flash.success("Invite email sent");
            });
        });
    }
  },
};
</script>
